import { Link, StaticQuery, graphql } from "gatsby";
import React from "react";
import styled from "styled-components";

import * as colors from "../utils/colors";

function PostList({ posts }: { posts: any }) {
  return (
    <ul>
      {posts.map(x => (
        <li key={x.path}>
          <Link to={x.path}>{x.title}</Link>
        </li>
      ))}
    </ul>
  );
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark(
          filter: {fileAbsolutePath: {regex: "/posts/.*\\.md$/"}},
          sort: { order: DESC, fields: [frontmatter___date] },
          limit: 15
        ) {
          edges {
            node {
              frontmatter {
                path
                date
                title
              }
            }
          }
        }
      }
    `}
    render={data => <PostList posts={data.allMarkdownRemark.edges.map(x => x.node.frontmatter)} />}
  />
);
