import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";

import Header from "./Header";
import PostList from "./PostList";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  grid-template-areas:
    "header header header"
    "... main sidebar"
    "footer footer footer";

  & > .main {
    grid-area: main;
  }
  & > .sidebar {
    grid-area: sidebar;
    margin: 1rem 0.75rem;
    header {
      font-size: 1.25rem;
      font-weight: 600;
    }
  }
`;

const Footer = styled.footer`
  margin: 1rem 0;
  text-align: center;
  grid-area: footer;
`;

const Layout = ({ children, noSidebar }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <Wrapper>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div className="main">{children}</div>

        {!noSidebar && (
          <div className="sidebar">
            <header>Latest Posts</header>
            <PostList />
          </div>
        )}

        <Footer>© {new Date().getFullYear()}, Ryan Hirsch</Footer>
      </Wrapper>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  noSidebar: PropTypes.bool,
};

export default Layout;
