import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";

import * as colors from "../utils/colors";
import backgroundPattern from "../images/circuit-board.png";
import logo from "../images/logo.png";
import ContactInfo from "./ContactInfo";

const SiteHeader = styled.header`
  grid-area: header;
  color: ${colors.grey0};
  background-image: url(${backgroundPattern});
  min-height: 200px;

  display: flex;
  justify-content: center;
  align-items: center;

  a {
    text-decoration: none;

    &:visited {
      color: inherit;
    }
  }
`;

const Header: React.SFC<{}> = () => (
  <SiteHeader>
    <Link to="/">
      <img src={logo} />
    </Link>
    <ContactInfo />
  </SiteHeader>
);

export default Header;
