import React from "react";
import { IoLogoTwitter, IoLogoGithub, IoLogoLinkedin } from "react-icons/io";
import styled from "styled-components";

const Card = styled.div`
  padding: 0.5rem;
  background-color: rgba(62, 76, 89, 0.8);
  position: absolute;
  right: 0;
  color: white;
  font-size: 1.2rem;
  a {
    color: white;
    display: flex;
    /* line-height: 2rem; */
    align-items: center;
    justify-content: flex-end;
  }
`;
const Item = styled.div`
  padding: 0.25rem 0;
`;

export default function ContactInfo() {
  return (
    <Card>
      <Item>Ryan.Hirsch@gmail.com</Item>
      <Item>
        <a href="https://github.com/RyanHirsch">
          <IoLogoGithub />
          /RyanHirsch
        </a>
      </Item>

      <Item>
        <a href="https://twitter.com/RyanHirsch">
          <IoLogoTwitter /> /RyanHirsch
        </a>
      </Item>
      <Item>
        <a href="https://www.linkedin.com/in/ryanjhirsch/">
          <IoLogoLinkedin /> /RyanJHirsch
        </a>
      </Item>
    </Card>
  );
}
