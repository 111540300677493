export const blue0 = "#035388";
export const blue1 = "#0B69A3";
export const blue2 = "#127FBF";
export const blue3 = "#1992D4";
export const blue4 = "#2BB0ED";
export const blue5 = "#40C3F7";
export const blue6 = "#5ED0FA"; // txt
export const blue7 = "#81DEFD";
export const blue8 = "#B3ECFF";
export const blue9 = "#E3F8FF";

export const grey0 = "#1F2933";
export const grey1 = "#323F4B";
export const grey2 = "#3E4C59"; // bg
export const grey3 = "#52606D";
export const grey4 = "#616E7C";
export const grey5 = "#7B8794";
export const grey6 = "#9AA5B1"; // fg
export const grey7 = "#CBD2D9";
export const grey8 = "#E4E7EB";
export const grey9 = "#F5F7FA";

export const pink0 = "#620042";
export const pink1 = "#870557";
export const pink2 = "#A30664";
export const pink3 = "#BC0A6F";
export const pink4 = "#DA127D";
export const pink5 = "#E8368F";
export const pink6 = "#F364A2";
export const pink7 = "#FF8CBA";
export const pink8 = "#FFB8D2";
export const pink9 = "#FFE3EC";

export const red0 = "#610316";
export const red1 = "#8A041A";
export const red2 = "#AB091E";
export const red3 = "#CF1124";
export const red4 = "#E12D39";
export const red5 = "#EF4E4E";
export const red6 = "#F86A6A";
export const red7 = "#FF9B9B";
export const red8 = "#FFBDBD";
export const red9 = "#FFE3E3";

export const yellow0 = "#8D2B0B";
export const yellow1 = "#B44D12";
export const yellow2 = "#CB6E17";
export const yellow3 = "#DE911D";
export const yellow4 = "#F0B429";
export const yellow5 = "#F7C948";
export const yellow6 = "#FADB5F";
export const yellow7 = "#FCE588";
export const yellow8 = "#FFF3C4";
export const yellow9 = "#FFFBEA";

export const green0 = "#014D40";
export const green1 = "#0C6B58";
export const green2 = "#147D64";
export const green3 = "#199473";
export const green4 = "#27AB83";
export const green5 = "#3EBD93";
export const green6 = "#65D6AD";
export const green7 = "#8EEDC7";
export const green8 = "#C6F7E2";
export const green9 = "#EFFCF6";
